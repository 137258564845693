/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import IM1 from "../../../assets/blog/remote-interview-550x350.avif";
import IM2 from "../../../assets/blog/software-engineering-roles-550x350.avif";
import IM3 from "../../../assets/blog/personal-branding-550x350.avif";
import { Link, useParams } from "react-router-dom";
import { Avatar } from "rizzui";
import "./blog-details.css";
import { CgCopy } from "react-icons/cg";
import { FaCheck } from "react-icons/fa";
import {
  FaFacebook,
  FaPinterest,
  FaRegClock,
  FaXTwitter,
} from "react-icons/fa6";
import IM from "../../../assets/blog/interview-anxiety-80x80.avif";
import Footer from "../Footer/footer";
import Subscribe from "../subscribe/Subscribe";
import axios from "axios";
import Loader from "../../Loader/Loader";
import { Helmet } from "react-helmet";
const DetailBlog = ({ isSidebarOpen }) => {
  
  const [blogDetails, setBlogDetails] = useState(null);
  const [topOnTheWeek, setTopOnTheWeek] = useState([]); 

  // const topOnTheWeek = [
  //   {
  //     id: 1,
  //     image: IM1,
  //     title: "Overcoming Interview Anxiety: Building Confidence",
  //   },
  //   {
  //     id: 2,
  //     image: IM2,
  //     title: "Preparing for Onsite vs. Remote Interviews",
  //   },
  //   {
  //     id: 3,
  //     image: IM3,
  //     title:
  //       "Understanding Different Software Engineering Roles and Responsibilities",
  //   },
  //   {
  //     id: 4,
  //     image: IM1,
  //     title: "Building a Strong Personal Brand for Career Success",
  //   },
  // ];

  const { slug } = useParams();
  const [icon, setIcon] = useState(<CgCopy />);

  const inputRef = useRef(null);

  
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = window.location.href;
    }
    window.scrollTo(0, 0);
  }, []);

  // Function to copy the input value to the clipboard
  const copyToClipboard = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
      setIcon(<FaCheck className="icon-check" />); // Change to check icon

      setTimeout(() => {
        window.getSelection().removeAllRanges();

        setIcon(<CgCopy className="icon-copy" />); // Revert to copy icon after 3 seconds
      }, 2000);
    }
  };

  const currentUrl = window.location.href;

  const shareToFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      currentUrl
    )}`;
    window.open(facebookUrl, "_blank", "noopener,noreferrer");
  };

  const shareToTwitter = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      currentUrl
    )}`;
    window.open(twitterUrl, "_blank", "noopener,noreferrer");
  };

  const shareToPinterest = () => {
    const pinterestUrl = `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(
      currentUrl
    )}`;
    window.open(pinterestUrl, "_blank", "noopener,noreferrer");
  };

  const [loading, setLoading] = useState(true);
   

 const fetchData = async () => {
   try {
     const response = await fetch(
       `${process.env.REACT_APP_BACKEND_URL}/blog/get-blog/${slug}`
     );
     const data = await response.json();

     // Format blog details
     setBlogDetails({
       category: data.data.category.name,
       metaTitle: data.data.metaTitle,
       metaDescription: data.data.metaDescription,
       categorySlug: data.data.category.slug,
       title: data.data.title,
       date: data.data.published_at,
       image: `${process.env.REACT_APP_BACKEND_URL}${data.data.blog_Image}`,
       content: data.data.description,
       tags: data.data.tags.map((tag) => ({
         label: tag.name,
         value: `${tag.slug}`,
       })),
     });

     // Format top on the week data
     setTopOnTheWeek(
       data.topOnTheWeeks.map((item, index) => ({
         id: item._id,
         image: `${process.env.REACT_APP_BACKEND_URL}${item.blog_Image}`,
         title: item.title,
         slug: item.slug,
       }))

    
     );
        setLoading(false);
   } catch (error) {
        setLoading(false)
     console.error("Error fetching data", error);
   }
 };

    useEffect(() => {
     
      fetchData();
    }, [slug]);
     useEffect(() => {
       fetchData();
     }, []);
 
       
        if (loading) {
    return <Loader />;  
  }
    
  console.log("blogDetails", blogDetails);
 
  return (
    <>
      {blogDetails && (
        <Helmet>
          <meta charSet="UTF-8" />
          <title>{blogDetails.metaTitle || "Blog"}</title>
          <meta
            name="description"
            content={blogDetails.metaDescription || "Blog Description"}
          />
        </Helmet>
      )}
      <div
        className={`blog-container ${
          isSidebarOpen ? "container" : " container-lg"
        }   `}
      >
        <div className="row">
          <div className="col-12 col-md-2  d-none d-lg-block">
            <div className="sc-main">
              <div className="category-container">
                <Link
                  to={`/category/${blogDetails?.categorySlug}`}
                  className="category-aviator"
                >
                  {blogDetails?.category ? blogDetails.category.charAt(0) : ""}
                </Link>
                <Link to={"#"} className="category-title_details">
                  {blogDetails?.category}
                </Link>
              </div>
              <div className="blog-timing">
                <FaRegClock className="me-3" size={15} /> {blogDetails?.date}
              </div>
              <div className="social-media">
                <div className="facebook" onClick={shareToFacebook}>
                  <FaFacebook />
                </div>
                <div className="twitter" onClick={shareToTwitter}>
                  <FaXTwitter className="twitter" />
                </div>
                <div className="pintrest" onClick={shareToPinterest}>
                  <FaPinterest />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-7">
            <div className="cs-entry__header cs-entry__header-standard">
              <div className="cs-entry__header-inner">
                <div className="cs-entry__content-wrap">
                  <div className="sc-mid-title">
                    <h1>
                      <span>{blogDetails?.title}</span>
                    </h1>
                  </div>

                  <div className="cs-entry__post-meta">
                  
                    <div className="cs-meta-date">{blogDetails?.date}</div>
                  </div>
                </div>

                <figure className="cs-entry__post-media post-media">
                  <img
                    loading="lazy"
                    alt="banner"
                    width={800}
                    height={457}
                    src={blogDetails?.image}
                  />
                </figure>
              </div>
            </div>
            <div className="cs-entry__content-container">
              <div
                className="entry-content"
                dangerouslySetInnerHTML={{ __html: blogDetails?.content }}
              />

              <div className="cs-entry__tags">
                <ul>
                  {blogDetails &&
                    blogDetails?.tags.map((data, index) => {
                      return (
                        <li key={index}>
                          <Link to={`/tag/${data?.value}`} className="sde-tags">
                            {data?.label}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </div>

              <div className="cs-entry__after-share-buttons">
                <div className="cs-entry__after-share-buttons-title">
                  <h5>Share this article</h5>
                </div>
                <div className="pk-share-buttons-wrap">
                  <div className="pk-share-buttons-items">
                    <div className="social-margin">
                      <FaFacebook
                        className="facebookTwo "
                        onClick={shareToFacebook}
                      />
                    </div>
                    <div className="social-margin">
                      <FaXTwitter
                        className="twitterTwo "
                        onClick={shareToTwitter}
                      />
                    </div>
                    <div>
                      <FaPinterest
                        className="pintrestTwo"
                        onClick={shareToPinterest}
                      />
                    </div>
                  </div>
                  <div className="cs-entry__after-share-buttons-link">
                    <div className="cs-entry__after-share-buttons-input-group">
                      <input
                        ref={inputRef}
                        value={window.location.href} // Show current URL
                        readOnly
                        className="cs-entry__after-share-buttons-text"
                      />
                      <button
                        onClick={copyToClipboard}
                        className="cs-entry__after-share-buttons-copy"
                      >
                        {icon}
                      </button>
                    </div>
                    <span className="cs-entry__after-share-buttons-text">
                      Shareable URL
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 mt-5 mt-lg-0">
            <aside className="top-on-the-weeks widget">
              <div className="cs-sidebar__inner">
                <h2 className="wp-block-heading is-style-cs-heading-sidebar">
                  Top on the week
                </h2>
                <hr className="mb-5" />
              </div>
              <div className="cs-posts-area__main ">
                {topOnTheWeek.map((article, index) => (
                  <article className="top-on-the-teams-inside" key={article.id}>
                    <div className="cs-entry__outer ">
                      <div className="row">
                        <div className="col-3 left-image">
                          <div className="cs-entry__inner cs-entry__thumbnail">
                            <div className="cs-overlay-background">
                              <img
                                loading="lazy"
                                src={article.image}
                                alt="Thumbnail Image"
                                className="thumbnail-image"
                              />
                              <div className="overlay">
                                <span className="overlay-text">
                                  {index + 1}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-9">
                          <Link to={`/blog/${article?.slug}`}>
                            <h2 className="cs-text-right">
                              <Link>{article.title}</Link>
                            </h2>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </article>
                ))}
              </div>
            </aside>
          </div>
        </div>
      </div>
      <Subscribe />
      <Footer />
    </>
  );
};

export default DetailBlog;

import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setData } from "../../../redux/features/category/categorySlice";
import "./add.css";
import Select from "react-select";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FileInput } from "rizzui";
const AddBlog = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

    const fileInputRef = useRef(null);
   const [selectedFile, setSelectedFile] = useState(null);
   const [previewUrl, setPreviewUrl] = useState(null);
  // const styles = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     fontSize: "14px",
  //   }),
  // };
  const styles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "14px",
    }),
    option: (styles, { data, isDisabled, isFocused = false, isSelected }) => {
      // const color = chroma(data.color);
      console.log("isFocused", isFocused);
      return {
        ...styles,

        // backgroundColor: isDisabled
        //   ? undefined
        //   : isFocused
        //   ? "#BF66B6"
        //   : isSelected
        //   ? "#c00eae"
        //   : undefined,

        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#BF66B6"
          : isFocused
          ? "#c00eae"
          : undefined,

        color: isDisabled
          ? "#ccc"
          : isFocused || isSelected
          ? "white"
          : data.color,
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#c00eae"
              : "#BF66B6"
            : undefined,
        },
      };
    },
  };

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [categoryValue, setCategoryValue] = useState();
  const [tagValue, setTagValue] = useState(null);
  const [value, setValue] = useState("");
  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    metaTitle: Yup.string().required("Meta title is required"),
    metaDescription: Yup.string().required("Meta Description is required"),
    slug: Yup.string().required("Slug is required"),
    reading_time: Yup.string().required("Reading Time is required"),
    // .matches(/^\d+$/, "Reading Time must be a number"), // Assuming reading_time is a number in string format

    category_id: Yup.string()
      .required("Category ID is required")
      .matches(
        /^[a-fA-F0-9]{24}$/,
        "Category ID must be a valid MongoDB ObjectId"
      ), // Adjust pattern as needed for your use case

    blog_Image: Yup.mixed().required("Blog Image is required"),
    // .test("fileSize", "File Size is too large", (value) => {
    //   // Optional: Validate file size if needed
    //   return value && value.size <= 5 * 1024 * 1024; // Example: 5MB size limit
    // })
    // .test("fileType", "Unsupported File Format", (value) => {
    //   // Optional: Validate file type if needed
    //   return value && ["image/jpeg", "image/png"].includes(value.type);
    // }),

    tags: Yup.array()
      .of(Yup.string().required("Each tag is required")) // Validate each tag as a string
      .min(1, "At least one tag is required") // Ensure at least one tag is provided
      .required("Tags are required"),
  });

  const initialValues = {
    title: "",
    slug: "",
    description: "",
    metaTitle: "",
    metaDescription: "",
    reading_time: "",
    category_id: "",
    blog_Image: null,
    tags: [],
  };
  const fetchData = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/blog/category/get-category`;
      const response = await axios.get(url);
      
      const formattedOptions = response.data.data.map((category) => ({
        label: category.name, // Display name
        value: category._id, // Value to use in form submission
      }));
      setCategoryOptions(formattedOptions);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const fetchDataTags = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/blog/tags`;
      const response = await axios.get(url);
       
      const formattedOptions = response.data.data.map((category) => ({
        label: category.name,
        value: category._id,
      }));
      setTagOptions(formattedOptions);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDataTags();
  }, []);

  // Handle form submission
 const onSubmit = async (values, { resetForm }) => {
   try {
     // Create a FormData object
     const formData = new FormData();
     formData.append("title", values.title);
     formData.append("slug", values.slug);
     formData.append("description", values.description);

     formData.append("metaTitle", values.metaTitle);
     formData.append("metaDescription", values.metaDescription);
     formData.append("reading_time", values.reading_time);
     formData.append("category_id", values.category_id);

     // Append each tag individually
     values.tags.forEach((tag, index) => {
       formData.append(`tags[${index}]`, tag);
     });

     // Append the image file
     if (values.blog_Image) {
       formData.append("blog_Image", values.blog_Image);
     }

     // Send the POST request with the FormData object
     await axios
       .post(`${process.env.REACT_APP_BACKEND_URL}/blog/add-blog`, formData, {
         headers: {
           "Content-Type": "multipart/form-data",
         },
       })
       .then(async (res) => {
         toast.success("Blog Added Successfully!");

         // Fetch updated data
         await fetchData();

         // Navigate to a different route
         navigate("/admin/blogs");

         // Reset the form
         resetForm();
       });
   } catch (error) {
     console.error("Error adding blog:", error);
     let errorMessage = "Something Went Wrong!";

     if (error.response && error.response.data && error.response.data.message) {
       errorMessage = error.response.data.message;
     }

     toast.error(errorMessage);
   }
 };

    const handleFileChange = (event) => {
      const file = event?.target?.files[0];
      if (file) {
        setSelectedFile(file.name);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setSelectedFile(null);
        setPreviewUrl(null);
      }
    };
    

    const handleButtonClick = () => {
      fileInputRef.current.click();
    };
  return (
    <div className="container">
      <Card className="card-formatter border border-0 card rounded-md bg-white shadow shadow-sm p-4">
        <h3 className="mb-4 title-admin">Add Blog</h3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <div className="grid-container">
                <div className="grid-item">
                  <label>
                    Select Category <span style={{ color: "red" }}>*</span>
                  </label>
                  <Select
                    options={categoryOptions}
                    isClearable={false}
                    styles={styles}
                    onChange={(e) => {
                      setCategoryValue(e);

                      setFieldValue("category_id", e.value);
                    }}
                    classNamePrefix="select"
                    value={categoryValue}
                  />
                  <ErrorMessage
                    name="category_id"
                    component="div"
                    className="text-danger mt-1"
                  />
                </div>
                <div className="grid-item">
                  <div className="form-group">
                    <label htmlFor="title ">
                      Blog Title<span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      id="title"
                      name="name"
                      className="form-control"
                      placeholder="Enter blog title"
                      onChange={(e) => {
                        setFieldValue("title", e?.target?.value);
                      }}
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="text-danger mt-1"
                    />
                  </div>
                </div>
                <div className="grid-item">
                  <label>Select Tags</label>
                  <Select
                    options={tagOptions}
                    isClearable={false}
                    closeMenuOnSelect={false}
                    isMulti
                    styles={styles}
                    onChange={(e) => {
                      setTagValue(e);
                      const selectedValues = e.map((option) => option.value);
                      console.log("selectedValues", selectedValues);
                      setFieldValue("tags", selectedValues);
                    }}
                    classNamePrefix="select"
                    value={tagValue}
                  />
                  <ErrorMessage
                    name="tags"
                    component="div"
                    className="text-danger mt-1"
                  />
                </div>

                <div className="grid-item">
                  <div className="form-group">
                    <label htmlFor="title ">
                      Slug<span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      id="title"
                      name="name"
                      className="form-control"
                      placeholder="e.g., understanding-the-power-of-docker-in-devops"
                      onChange={(e) => {
                        setFieldValue("slug", e?.target?.value);
                      }}
                    />
                    <ErrorMessage
                      name="slug"
                      component="div"
                      className="text-danger mt-1"
                    />
                  </div>
                </div>
              </div>
              <div className="grid-item">
                <div className="form-group">
                  <label htmlFor="metaTitle ">
                    Meta Title<span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="text"
                    id="metaTitle"
                    name="metaTitle"
                    className="form-control"
                    placeholder="Enter your meta title"
                    onChange={(e) => {
                      setFieldValue("metaTitle", e?.target?.value);
                    }}
                  />
                  <ErrorMessage
                    name="metaTitle"
                    component="div"
                    className="text-danger mt-1"
                  />
                </div>
              </div>
              <div className="grid-item">
                <div className="form-group">
                  <label htmlFor="metaDescription ">
                    Meta Description<span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="text"
                    id="metaDescription"
                    name="metaDescription"
                    className="form-control"
                    placeholder="Enter your meta description"
                    onChange={(e) => {
                      setFieldValue("metaDescription", e?.target?.value);
                    }}
                  />
                  <ErrorMessage
                    name="metaDescription"
                    component="div"
                    className="text-danger mt-1"
                  />
                </div>
              </div>
              <div className="grid-item">
                <label>
                  Description <span style={{ color: "red" }}>*</span>
                </label>
                <div className="text-editor">
                  {/* <ReactQuill
                    theme="snow"
                    placeholder="Enter your Blog Description"
                    value={value}
                    onChange={(e) => {
                      setValue(e);
                      setFieldValue("description", e);
                    }}
                  />*/}
                  <EditorToolbar />
                  <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={(e) => {
                      setValue(e);
                      setFieldValue("description", e);
                    }}
                    placeholder={"Write something awesome..."}
                    modules={modules}
                    formats={formats}
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-danger mt-1"
                  />
                </div>
              </div>
              <div className="grid-container">
                <div className="grid-item">
                  <div className="form-group">
                    <label htmlFor="reading_time ">
                      Reading Time<span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      id="reading_time"
                      name="name"
                      className="form-control"
                      placeholder="Enter reading time"
                      onChange={(e) => {
                        setFieldValue("reading_time", e?.target?.value);
                      }}
                    />
                    <ErrorMessage
                      name="reading_time"
                      component="div"
                      className="text-danger mt-1"
                    />
                  </div>
                </div>
                <div className="grid-item mt-4">
                  <div className="form-group">
                    <div className="image-upload-preview">
                      <input
                        type="file"
                        id="imageInput"
                        accept="image/*"
                        onChange={(e) => {
                          console.log("e", e.target.files[0]);
                          setFieldValue("blog_Image", e?.target?.files[0]);
                          handleFileChange(e);
                        }}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                      />
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleButtonClick}
                      >
                        {selectedFile ? selectedFile : "Choose file"}
                      </button>
                    </div>
                    <ErrorMessage
                      name="blog_Image"
                      component="div"
                      className="text-danger mt-1"
                    />
                  </div>
                </div>
                <div className="grid-item mt-4 d-none">
                  {previewUrl && (
                    <div className="image-preview">
                      <img
                        src={previewUrl}
                        alt="Selected Preview"
                        style={{ maxWidth: "100%", maxHeight: "100px" }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <button
                type="submit"
                className="admin-button  mt-3"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default AddBlog;

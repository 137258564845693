import React, { useState } from 'react'
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { display } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineModeEdit } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ActionIcon } from 'rizzui';
import DeleteConfirmationModal from './delete/DeleteModal';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setData } from '../../redux/features/Blog/blogSlice';
 
  


const tooltip = (
  <Tooltip id="tooltip">
    <strong>Edit</strong> 
  </Tooltip>
);
const tooltipView = (
  <Tooltip id="tooltip">
    <strong>View</strong> 
  </Tooltip>
);
const tooltipDelete = (
  <Tooltip id="tooltip">
    <strong>Delete</strong> 
  </Tooltip>
);




const Table = ({data}) => {
 const [showModal, setShowModal] = useState(false);
 const [itemToDelete, setItemToDelete] = useState(null);
  const dispatch = useDispatch();

     const handleOpenModal = (id) => {
       setItemToDelete(id);
       setShowModal(true);
     };

     const handleCloseModal = () => {
       setShowModal(false);
       setItemToDelete(null);
     };
      const fetchData = async () => {
        try {
          // Use REACT_APP_BACKEND_URL from environment variables
          const url = `${process.env.REACT_APP_BACKEND_URL}/blog/get-all-blogs`;
          const response = await axios.get(url).then((res) => {
            console.log("response", res);
            dispatch(setData(res.data.data));
          });
        } catch (err) {
         
        } finally {
        
        }
      };
     const handleConfirmDelete = async() => {
       // Implement delete logic here
       

       try {
      // Perform the POST request to add a new category
      await axios
        .delete(
          `${process.env.REACT_APP_BACKEND_URL}/blog/delete-blog/${itemToDelete}`
        )
        .then(async (res) => {
          // Notify success and perform additional actions
          toast.success("Blog Deleted Successfully!");

          // Fetch updated data
          await fetchData();

          // Close the modal
          handleCloseModal();
        });
    } catch (error) {
      // Handle errors
      console.error("Error adding category:", error);
      let errorMessage = "Something Went Wrong!"; // Default error message
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message; // Extract error message from API response
      }

      console.error("Error adding category:", error);
      toast.error(errorMessage); // Display the error message
    }

       
 
     };

     const columns = [
       { field: "title", headerName: "Title", width: 150 },
       {
         field: "blog_Image",
         headerName: "Blog Image",
         width: 200,
         renderCell: (params) => {
            const baseUrl = process.env.REACT_APP_BACKEND_URL;
           //
           const imageUrl = `${baseUrl}/${params.row.blog_Image}`;

           return (
             <a href={imageUrl} target="_blank" rel="noopener noreferrer">
               <img
                 src={imageUrl}
                 alt="Blog"
                 style={{ width: "100px", height: "50px", borderRadius: "5px" }} // Adjust styling as needed
               />
             </a>
           );
         },
       },
       {
         field: "category",
         headerName: "Category",
         width: 150,
         renderCell: (params) => {
           // Access the 'name' property from the 'category' object
           return <div>{params.row.category?.name}</div>;
         },
       },
       {
         field: "tags",
         headerName: "Tags",
         width: 200,
         renderCell: (params) => {
           // Iterate over the tags array and display each tag's name
           return (
             <div>
               {params.row.tags && params.row.tags.length > 0 ? (
                 params.row.tags.map((tag) => (
                   <div key={tag._id}>{tag.name}</div>
                 ))
               ) : (
                 <div>No tags available</div>
               )}
             </div>
           );
         },
       },
       { field: "description", headerName: "Description", width: 250 },
       { field: "reading_time", headerName: "Read Time", width: 150 },
         { field: "slug", headerName: "Slug", width: 150 },

       { field: "createdAt", headerName: "Created At", width: 200 },
       { field: "updatedAt", headerName: "Updated At", width: 200 },

       {
         field: "actions",
         headerName: "Actions",
         width: 250,
         renderCell: (params) => {
           const handleDelete = () => {
             // Implement delete logic here
             console.log("Delete", params.row.id);
             // Example of navigation after delete
             // navigate('/some-page-after-delete');
           };

           return (
             <div
               style={{
                 display: "flex",
                 justifyContent: "start",
                 width: "100%",
                 alignItems: "center",
                 gap: 5,
                 textAlign: "center",
                 marginTop: "10px",
               }}
             >
               <OverlayTrigger placement="top" overlay={tooltip}>
                 <Link to={`/admin/blogs/edit-blog/${params?.row?.slug}`}>
                   <div className="action-button">
                     <MdOutlineModeEdit style={{ cursor: "pointer" }} />
                   </div>
                 </Link>
               </OverlayTrigger>
               <OverlayTrigger placement="top" overlay={tooltipView}>
                 <Link to={`/admin/blogs/view-blog/${params?.row?.slug}`}>
                   <div className="action-button">
                     <FaEye style={{ cursor: "pointer" }} />
                   </div>
                 </Link>
               </OverlayTrigger>
               <OverlayTrigger placement="top" overlay={tooltipDelete}>
                 <div className="action-button">
                   <MdDelete
                     style={{ cursor: "pointer" }}
                     onClick={() => handleOpenModal(params.row.slug)}
                   />
                 </div>
               </OverlayTrigger>
             </div>
           );
         },
       },
     ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        componentsProps={{
          pagination: {
            sx: {
              width: "500px",
            },
          },
        }}
        getRowId={(row) => row._id}
        pageSizeOptions={[5, 10]}
      />
      <DeleteConfirmationModal
        show={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmDelete}
      />
    </div>
  );
}

export default Table